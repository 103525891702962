<template>
  <div>
    <button id="connBtn">连接</button>
    <input type="text" id="msg" />
    <button id="sendBtn" @click="send()">发送</button>
    <button id="closeBtn" @click="closeWebsocket()">关闭</button>
    <div id="message"></div>
    <div class="text" contenteditable="true" id="msg"></div>
    <el-input
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 8 }"
      placeholder="请输入内容"
      v-model="textarea2"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ws: "",
      textarea2: "",
    };
  },
  created() {},
  methods: {
    send(){
      console.log(document.getElementsByClassName('text')[0].innerHTML)
    },
    closeWebsocket(){
      console.log('213')
    },
    getClipboardFiles(event) {
      let items = event.clipboardData && event.clipboardData.items;
      let file = null;
      if (items && items.length) {
        // 检索剪切板items
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            file = items[i].getAsFile();
          }
        }
      }
      console.log(file)
      if (!file) {
        this.$message.error("粘贴内容不是图片");
        return;
      }
      this.handleFilesAdd(file);
    },
  },
  mounted() {
    document.addEventListener("paste", this.getClipboardFiles);
  },
};
</script>

<style scoped>
.text {
  text-align: left;
}
</style>